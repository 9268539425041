<template>
  <v-card elevation=4 outlined class="rounded-lg">
    <UnsavedChanges
      text="Unsaved Changes"
      :changes="changes"
      :changes_valid="changes_valid"
      :saveChanges="saveChanges"
      :discardChanges="discardChanges"
    ></UnsavedChanges>
    <v-card-title class="py-2">
      Images
    </v-card-title>

    <v-divider/>

    <v-card-text>
      <v-form v-model="changes_valid">
        <SettingsTabsImagesOptions
          :settings="newData.settings"
        ></SettingsTabsImagesOptions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
const clone = require("clone");
import UnsavedChanges from "@/components/UnsavedChanges.vue";
import SettingsTabsImagesOptions from "@/components/settings/SettingsTabsImagesOptions.vue";
import { userLogic } from "@/mixins/userMixin.js";
export default {
  mixins: [userLogic],
  components: {
    UnsavedChanges,
    SettingsTabsImagesOptions,
  },
  data() {
    return {
      changes_valid: true,
      newData: {
        settings: {},
      },
      oldData: {
        settings: {},
      },
    }
  },
  mounted() {
    this.newData.settings = this.$store.getters.getImageSettings;
    this.oldData.settings = clone(this.$store.getters.getImageSettings);
  },
  computed: {
    changes() {
      return JSON.stringify(this.oldData.settings) != JSON.stringify(this.newData.settings);
    }
  },
  methods: {
    getChanges() {
      let changes = {};
      for (var key in this.newData.settings) {
        if (this.newData.settings[key] != this.oldData.settings[key]) {
          changes[key] = this.newData.settings[key];
        }
      }
      return changes;
    },
    saveChanges() {
      let settings = this.getChanges();
      this.updateImageSettings(settings).then(() => {
        this.oldData.settings = clone(this.newData.settings);
      });
    },
    discardChanges() {
      this.newData.settings = clone(this.oldData.settings);
    }
  }
}
</script>