<template>
  <v-card elevation=4 outlined class="rounded-lg">
    <UnsavedChanges
      text="Unsaved Changes"
      :changes="changes"
      :changes_valid="changes_valid"
      :saveChanges="saveChanges"
      :discardChanges="discardChanges"
    ></UnsavedChanges>
    <v-card-title class="py-2">
      Weightings
      <v-spacer/>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" class="px-1" color="primary">
            <v-icon >mdi-help-circle-outline</v-icon>
          </v-chip>
        </template>
        <span v-html="'These values are used to calculate product ratings.<br>Higher values mean field is <b>more</b> important, lower values mean <b>less</b> important.<br><b>Recommended values</b>: Good: 2 | Average: 1 | Bad: 0'"></span>
      </v-tooltip>
    </v-card-title>

    <v-divider/>

    <v-card-text>
      <v-form v-model="changes_valid">
        <v-row>
          <v-col>
            <v-row v-for="(value, key) in left_weightings" :key="key" class="pb-2">
              <v-col>
                <span class="font-weight-bold text-uppercase">{{ key }}</span>
                <v-row v-for="(weighting, i) in Object.keys(value)" :key="i" dense>
                  <v-col>
                    <v-slider v-model.number="value[weighting]" :label="weighting_names[weighting]" min=0 max=10 ticks thumb-label step=1 hide-details="auto"></v-slider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-divider vertical class="my-n1"/>

          <v-col>
            <v-row v-for="(value, key) in right_weightings" :key="key" class="pb-2">
              <v-col>
                <span class="font-weight-bold text-uppercase">{{ key }}</span>
                <v-row v-for="(weighting, i) in Object.keys(value)" :key="i" dense>
                  <v-col>
                    <v-slider v-model.number="value[weighting]" :label="weighting_names[weighting]" min=0 max=10 ticks thumb-label step=1 hide-details="auto"></v-slider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
const clone = require("clone");
import UnsavedChanges from "@/components/UnsavedChanges.vue"
import { metricsLogic } from "@/mixins/metricsMixin";
export default {
  mixins: [metricsLogic],
  components: {
    UnsavedChanges
  },
  created() {
    this.getWeightings().then(weightings => {
      this.newData.weightings = weightings;
      this.oldData.weightings = clone(weightings);
    });
  },
  computed: {
    left_weightings() {
      let weightings = ['seo', 'image', 'website'];
      return Object.fromEntries(Object.entries(this.newData.weightings).filter(([key]) => weightings.some(el => key.includes(el))));
    },
    right_weightings() {
      let weightings = ['core', 'variant'];
      return Object.fromEntries(Object.entries(this.newData.weightings).filter(([key]) => weightings.some(el => key.includes(el))));
    },
    changes() {
      return JSON.stringify(this.oldData.weightings) != JSON.stringify(this.newData.weightings);
    },
  },
  data() {
    return {
      newData: {
        weightings: {},
      },
      oldData: {
        weightings: {},
      },
      changes_valid: true,
      weighting_names: {
        'meta_title': 'Meta Title',
        'meta_description': 'Meta Description',
        'name': 'Name',
        'brand': 'Brand',
        'images': 'Images',
        'description': 'Description',
        'description_video': 'Description - Video',
        'alt_text': 'Alt Text',
        'barcode': 'Barcode',
        'tags_categories': 'Tags/Categories'
      }
    }
  },
  methods: {
    saveChanges() {
      this.updateWeightings(this.newData.weightings).then(() => {
        this.oldData.weightings = clone(this.newData.weightings);
        this.$store.dispatch("showSnackbar", {text: "Weightings Updated Successfully!", color: "success", timeout: 2500});
      });
    },
    discardChanges() {
      this.newData.weightings = clone(this.oldData.weightings);
    }
  }
}
</script>