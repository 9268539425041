export default {
  data() {
    return {
      priceLevels: [],
    }
  },
  methods: {
    getPriceLevels() {
      this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/priceLevels`)
        .then(result => {
          this.priceLevels = result.data.priceLevels;
        })
        .catch(err => {
          console.log(err);
        });
    },
    createPriceLevel(priceLevel) {
      this.$store.dispatch("performingUpdate", true);
      this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/priceLevels`, priceLevel)
        .then(result => {
          // Add the new ID to the price level object
          priceLevel['priceLevel_id'] = result.data.priceLevel_id;

          // Update the displayed data
          this.priceLevels.push(priceLevel);

          this.$store.dispatch("performingUpdate", false);
          this.$store.dispatch("showSnackbar", {text: "Price Level Created Successfully!", color: "success", timeout: 2500});
        })
        .catch(err => {
          console.log(err);
        });
    },
    updatePriceLevel(priceLevel) {
      this.$store.dispatch("performingUpdate", true);
      this.$axios.put(`${process.env.VUE_APP_API_BASE_URL}/priceLevels/${priceLevel.priceLevel_id}`, priceLevel)
        .then(() => {
          this.$store.dispatch("performingUpdate", false);
          this.$store.dispatch("showSnackbar", {text: "Price Level Updated Successfully!", color: "success", timeout: 2500});
        })
        .catch(err => {
          console.log(err);
        });
    },
    deletePriceLevel(priceLevel_id) {
      this.$store.dispatch("performingUpdate", true);
      this.$axios.delete(`${process.env.VUE_APP_API_BASE_URL}/priceLevels/${priceLevel_id}`)
        .then(() => {
          this.priceLevels.splice(this.priceLevels.findIndex(x => x.priceLevel_id === priceLevel_id), 1);
        this.$store.dispatch("performingUpdate", false);
          this.$store.dispatch("showSnackbar", {text: "Price Level Deleted Successfully!", color: "success", timeout: 2500});
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};