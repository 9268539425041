<template>
<v-container fluid>
  <Header
    title="Settings"
    :divider="false"
    :options="[]"
  ></Header>

  <SettingsTabs
  :users="users"
  :roles="roles"
  :organization="organization"
  :loading="loading"
  :access="access"
  :requestUsers="requestUsers"
  :createUser="createUser"
  :deleteUser="deleteUser"
  :goToUser="goToUser"
  :resetDatabase="resetDatabase"
  :NODE_ENV="NODE_ENV"
  ></SettingsTabs>
</v-container>
</template>

<script>
import Header from "@/components/Header.vue"
import SettingsTabs from '../components/settings/SettingsTabs.vue'
export default {
  data() {
    return {
      access: this.$store.getters.getAccessLevel['users'],
      users: [],
      roles: [],
      organization: this.$store.getters.getOrganizationSettings,
      loading: false,
    }
  },
  components: {
    Header,
    SettingsTabs,
  },
  computed: {
    NODE_ENV: function () {
      return process.env.NODE_ENV;
    }
  },
  mounted: function() {
    this.getUsers();
    this.getRoles();
  },
  methods: {
    resetDatabase() {
      this.$store.dispatch("logout")
        .then(() => {
          return this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/reset`, {headers: {"X-Authorization": this.$store.getters.getToken}})
        })
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    requestUsers(request) {
      // Add the requesters name and email to the request
      request['requester_name'] = `${this.$store.getters.getUser.givenName} ${this.$store.getters.getUser.familyName}`;
      request['requester_email'] = `${this.$store.getters.getUser.email}`;
      this.$axios
        .put(`${process.env.VUE_APP_API_BASE_URL}/users`, {request: request})
        .then(() => {
          this.$store.dispatch("showSnackbar", {text: "Additional Users Request Submitted!", color: "success", timeout: 5000});
        })
        .catch(err => {
          console.log(err);
        });
    },
    createUser(action, new_user) {
      let payload = {
        username: new_user.username,
        givenName: new_user.given_name,
        familyName: new_user.family_name,
        email: new_user.email,
        password: new_user.password,
        role_id: new_user.role_id,
        user_access: {
          website_no_access: JSON.stringify(new_user.user_access.website_no_access),
          brand_no_access: JSON.stringify(new_user.user_access.brand_no_access)
        }
      }
      return this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/users`, payload)
        .then(result => {
          // Behaviours based on action type from create
          if (action === 'normal') {
            this.goToUser(result.data.userId);
          } else if (action === 'another') {
            this.users.push({user_id: result.data.userId, username: new_user.username, given_name: new_user.given_name, family_name: new_user.family_name, email: new_user.email, me: 'No', role_name: this.roles.find(x => x.role_id === new_user.role_id).role_name});
            this.$store.dispatch("showSnackbar", {text: "User Created Successfully!", color: "success", timeout: 5000});
          } else if (action === 'close') {
            this.users.push({user_id: result.data.userId, username: new_user.username, given_name: new_user.given_name, family_name: new_user.family_name, email: new_user.email, me: 'No', role_name: this.roles.find(x => x.role_id === new_user.role_id).role_name});
            this.$store.dispatch("showSnackbar", {text: "User Created Successfully!", color: "success", timeout: 5000});
          }
          return true;
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("showSnackbar", {text: `${err.response.statusText}`, color: "error", timeout: 10000});
          return false;
        })
    },
    deleteUser(user_id) {
      this.$axios
        .delete(`${process.env.VUE_APP_API_BASE_URL}/users/${user_id}`)
        .then(() => {
          this.users.splice(this.users.findIndex(x => x.user_id === user_id), 1);
          this.$store.dispatch("showSnackbar", {text: "User Deleted Successfully!", color: "success", timeout: 5000});
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("showSnackbar", {text: "Delete Unsuccessful: You Cannot Delete Yourself!", color: "error", timeout: 5000});
        });
    },
    goToUser(user_id) {
      this.$router.push(`/settings/users/${user_id}`);
    },
    getUsers() {
      this.loading = true;
      this.$axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/users`)
        .then(result => {
          this.users = result.data;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getRoles() {
      this.$axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/users/roles`)
        .then(result => {
          this.roles = result.data;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
</script>