<template>
  <v-dialog v-model="show" max-width="35%" @click:outside="cancel()" @keydown.esc="cancel()">
    <v-card>
      <v-card-title class="headline" v-text="title"></v-card-title>
      <v-divider/>
      <v-card-text class="pa-4">
        <v-form ref="dialog_form" @submit.prevent>
          <v-text-field label="Name" v-model="priceLevel.name" :rules="[v => !!v || 'Name is required']" outlined hide-details=auto v-on:keyup.enter="onConfirm()"></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="grey" text class="body-2 font-weight-bold" @click="onCancel()">Cancel</v-btn>
        <v-btn color="success" class="body-2 font-weight-bold" outlined @click="onConfirm()">Confirm</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Confirm'
    },
    priceLevel: {
      type: Object,
      default: () => ({})
    },
    confirm: {
      type: Function,
      default: null
    },
    cancel: {
      type: Function,
      default: null
    }
  },
  methods: {
    onConfirm() {
      if (this.$refs.dialog_form.validate()) {
        this.confirm(this.priceLevel);
        this.$refs.dialog_form.resetValidation();
      }
    },
    onCancel() {
      this.$refs.dialog_form.resetValidation();
      this.cancel();
    }
  }
}
</script>