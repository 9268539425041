<template>
  <v-card elevation=4 outlined class="rounded-lg">
    <ActionDialog ref="delete_dialog" title="Deleting Price Level" color="error">
      <template v-slot>
        <span class="error--text" v-html="'Are you sure you want to delete this price level.<br>Warning! All prices related to this price level will be lost.'"></span>
      </template>
    </ActionDialog>

    <PriceLevelsDialog
      v-bind.sync="dialogProps"
    ></PriceLevelsDialog>

    <v-card-title class="py-2">
      Price Levels
      <v-spacer/>
      <v-btn color="primary" @click="showPriceLevelsDialog('Create Price Level').then(priceLevel => createPriceLevel(priceLevel)).catch(() =>{})" :disabled="!access.create_access">Add Price Level</v-btn>
    </v-card-title>

    <v-divider/>

    <v-card-text>
      <v-row>
        <v-col cols=12 sm=4 md=3 v-for="priceLevel in priceLevels" :key="priceLevel.priceLevel_id">
          <v-card hover outlined class="rounded-lg" style="cursor: auto; height: 66px">
            <v-card-title class="justify-center">
              <span class="text-truncate">{{ priceLevel.name }}</span>
            </v-card-title>

            <!-- Vertical Dots Menu -->
            <div style="position: absolute; right: 0; top: 0;">
              <v-menu bottom right absoloute>
                <!-- Button with vertical dots -->
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!-- Edit Button -->
                  <v-list-item v-if="access.update_access">
                    <v-btn block text @click="showPriceLevelsDialog('Update Price Level', priceLevel).then(priceLevel => updatePriceLevel(priceLevel)).catch(() =>{})">
                      Edit Price Level
                    </v-btn>
                  </v-list-item>
                  <!-- Audit Log Button -->
                  <v-list-item>
                    <v-btn block text @click="onAuditLog(priceLevel.priceLevel_id)">
                      Audit Log
                    </v-btn>
                    <AuditLogDialog
                      :show.sync="showAuditLog"
                      :logs="auditLogs"
                    ></AuditLogDialog>
                  </v-list-item>
                  <!-- Delete Button -->
                  <v-list-item v-if="access.delete_access">
                    <v-tooltip bottom :disabled="priceLevels.length > 1">
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn text block color="error" @click="$refs.delete_dialog.showDialog().then(() => deletePriceLevel(priceLevel.priceLevel_id)).catch(() => {})" :disabled="priceLevels.length === 1">Delete Price Level</v-btn>
                        </div>
                      </template>
                      <span>Must have at least one price level</span>
                    </v-tooltip>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import priceLevelsMixin from '@/mixins/priceLevelsMixin.js';
import { auditLogsLogic } from '@/mixins/auditLogsMixin.js';
import ActionDialog from '@/components/ActionDialog.vue';
import PriceLevelsDialog from '@/components/settings/SettingsTabsPriceLevelsDialog.vue';
import AuditLogDialog from '@/components/auditLogs/AuditLogDialog.vue';
export default {
  mixins: [auditLogsLogic, priceLevelsMixin],
  components: {
    ActionDialog,
    PriceLevelsDialog,
    AuditLogDialog,
  },
  data() {
    return {
      access: this.$store.getters.getAccessLevel['priceLevels'],
      showAuditLog: false,
      auditLogs: [],
      dialogProps: {}
    }
  },
  mounted() {
    this.getPriceLevels();
  },
  methods: {
    async onAuditLog(id) {
      this.auditLogs = await this.getAuditLogs(id, 'priceLevels');
      this.showAuditLog = true; 
    },
    showPriceLevelsDialog(title, priceLevel={}) {
      return new Promise((resolve, reject) => {
        const res = (priceLevel) => {
          this.dialogProps = {};
          resolve(priceLevel);
        }
        const rej = () => {
          this.dialogProps = {};
          reject();
        }
        this.dialogProps = { show: true, title: title, priceLevel: priceLevel, confirm: res, cancel: rej }
      })
    }
  }
}
</script>