<template>
  <v-card elevation=4 outlined class="rounded-lg">
    <v-card-title>
      Active Users for {{ organization.organization_name }}
      <v-spacer/>
      <v-btn v-if="users.length < organization.user_limit" color="primary" @click="createDialog = true;" :disabled="!access.create_access">Add New User</v-btn>
      <v-btn v-else color="primary" @click="$refs.request_dialog.showDialog({ amount: 1, message: '' }).then(data => requestUsers(data)).catch(() => {})" :disabled="!access.create_access">Request More Users</v-btn>
    </v-card-title>

    <v-card-subtitle>
      <v-tooltip left open-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <p v-bind="attrs" v-on="on">
            Used: {{ users.length }} / {{ organization.user_limit }}
          </p>
        </template>
        <span>The number of active user slots used/available to the organization</span>
      </v-tooltip>
    </v-card-subtitle>

    <CreateUser
    :show.sync="createDialog"
    :roles="roles"
    :access="access"
    :createUser="createUser"
    ></CreateUser>

    <ActionDialog ref="request_dialog" title="Requesting More Users" color="success">
      <template #default="{ options }">
        <v-text-field v-model.number="options.data.amount" label="Additional Users" type="number" min="1" outlined :rules="[v => !!v || 'Must be valid number']" @keydown.enter.prevent class="rounded-lg" />
        <v-textarea v-model="options.data.message" label="Message" placeholder="Enter a message here (optional)" persistent-placeholder outlined hide-details class="rounded-lg" />
      </template>
    </ActionDialog>

    <ActionDialog ref="delete_dialog" title="Deleting User" color="error">
      <template v-slot>
        <span class="error--text" v-html="'Are you sure you want to delete this user.<br>Warning! All access will be revoked from this user.'"></span>
      </template>
    </ActionDialog>

    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="10"
      item-key="user_id"
      sort-by="user_id"
      :loading="loading"
      class="elevation-0 hover-cursor"
      @click:row="(value) => goToUser(value.user_id)"
    >
      <template v-slot:[`item.givenName`]="{item}">
        <b>{{item.given_name}} {{item.family_name}}</b>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon :disabled="item.me === 'Yes'" @click.stop.prevent="$refs.delete_dialog.showDialog({ user: item }).then(data => deleteUser(data.user.user_id)).catch(() => {})">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CreateUser from "@/components/settings/CreateUser.vue";
import ActionDialog from "@/components/ActionDialog.vue";
export default {
  props: {
    users: Array,
    roles: Array,
    organization: Object,
    loading: Boolean,
    access: Object,
    showUserDialog: Boolean,
    requestUsers: Function,
    createUser: Function,
    deleteUser: Function,
    goToUser: Function,
  },
  components: {
    CreateUser,
    ActionDialog,
  },
  data() {
    return {
      selectedUser: {},
      createDialog: false,
    }
  },
  computed: {
    myToken() {
      return this.$store.getters.getToken;
    },
    headers() {
      let headers = [
        { text: 'Name', value: 'givenName', align: 'center' },
        { text: 'Email', value: 'email', align: 'center' },
        { text: 'Username', value: 'username', align: 'center' },
        { text: 'Role', value: 'role_name', align: 'center' }
      ];
      if (this.access.update_access) {
        headers.push({ text: 'Actions', value: 'actions', sortable: false });
      }
      return headers;
    }
  }
}
</script>

<style scoped>
.hover-cursor {
  cursor: pointer;
}
</style>