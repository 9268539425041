<template>
  <v-dialog v-model="show" max-width="900" @click:outside="$emit('update:show', false)" @keydown.esc="$emit('update:show', false)">
    <v-card>
    <!-- Create Site Head -->
    <v-container grid-list-md text-xs-center>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6">
            <h1 style="padding-bottom: 20px;">Create User</h1>
          </v-col>
          <v-col cols="12" sm="6" align="right">
            <v-btn color="primary" @click="validate(1)" v-if="current_step === 1">
              Continue
            </v-btn>

            <v-menu open-on-hover bottom left offset-y v-if="current_step === 2">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" @click="create('normal')">Save</v-btn>
              </template>
              <v-list nav>
                <v-list-item link @click="create('another')">
                  <v-list-item-content>
                    <v-list-item-title>Save & New</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="create('close')">
                  <v-list-item-content>
                    <v-list-item-title>Save & Close</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn text @click="cancel()">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- Create Site Stepper -->
      <v-stepper v-model="current_step" non-linear flat>
        <v-stepper-header>
          <v-stepper-step :complete="current_step > 1" :rules="[value => !!step_1_valid]" :step="1" editable @click="validate(2)">
            User Details
            <small v-if="!step_1_valid" style="color: red;">Missing Details</small>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="current_step > 2" :rules="[v => !!step_2_valid]" :step="2" editable @click="validate(1)">
            User Access
            <small v-if="!step_2_valid" style="color: red;">Missing Details</small>
          </v-stepper-step>
        </v-stepper-header>

        <!-- Step 1 - Main Website Details -->
        <v-stepper-content :step="1">
          <v-form ref="user_details">
            <v-row>
              <v-col cols=12 style="padding-top: 16px">
                <v-text-field label="Username" v-model="user.username" :rules="rules.username" :readonly="!access.update_access" outlined></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols=6>
                <v-text-field label="Given Name" v-model="user.given_name" :rules="rules.given_name" :readonly="!access.update_access" outlined></v-text-field>
              </v-col>

              <v-col cols=6>
                <v-text-field label="Family Name" v-model="user.family_name" :rules="rules.family_name" :readonly="!access.update_access" outlined></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols=12>
                <v-text-field label="Email" v-model="user.email" :rules="rules.email" :readonly="!access.update_access" type="email" outlined></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols=6>
                <v-text-field 
                  v-model="user.password"
                  label="Password"
                  :rules="rules.password"
                  :type="show_password ? 'text' : 'password'"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :append-outer-icon="'mdi-clipboard-multiple-outline'"
                  @click:append="show_password = !show_password"
                  @click:append-outer="copyPassword"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols=6>
                <v-text-field 
                  v-model="user.password_confirm"
                  label="Confirm Password"
                  :rules="rules.password_confirm"
                  :type="show_password ? 'text' : 'password'"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :append-outer-icon="'mdi-clipboard-multiple-outline'"
                  @click:append="show_password = !show_password"
                  @click:append-outer="copyPassword"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols=12>
                <v-select
                  label="Role"
                  v-model="user.role_id"
                  :items="roles"
                  item-text="role_name"
                  item-value="role_id"
                  :rules="rules.role"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>

        <!-- Step 2 - User Access -->
        <v-stepper-content :step="2">
          <v-form ref="user_access">
            <v-row>
              <v-col cols=12 style="padding-top: 16px">
                <v-autocomplete
                  label="Deny Website Access"
                  v-model="user.user_access.website_no_access"
                  :items="websites"
                  item-text="name"
                  item-value="website_id"
                  :readonly="!access.update_access"
                  :clearable="access.update_access"
                  outlined
                  chips
                  small-chips
                  deletable-chips
                  multiple
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols=12>
                <v-autocomplete
                  label="Deny Brand Access"
                  v-model="user.user_access.brand_no_access"
                  :items="brands"
                  item-text="name"
                  item-value="brand_id"
                  :readonly="!access.update_access"
                  :clearable="access.update_access"
                  outlined
                  chips
                  small-chips
                  deletable-chips
                  multiple
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
      </v-stepper>
    </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
var clone = require('clone')
import { brandsCore } from "@/mixins/brandsMixin.js";
import { websitesCore } from "@/mixins/websitesMixin.js";
export default {
  mixins: [brandsCore, websitesCore],
  data() {
    return {
      current_step: 1,
      step_1_valid: true,
      step_2_valid: true,
      show_password: false,
      user: {
        user_access: {}
      },
      rules: {
        username: [v => !!v || 'Username is required'],
        given_name: [v => !!v || 'Given Name is required'],
        family_name: [v => !!v || 'Family Name is required'],
        email: [v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid'],
        password: [v => !!v || "Password is required", v => (v || '').length >= 8 || 'Password must be at least 8 characters long', v => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]\\|;:'"?/>.<,])[A-Za-z\d!@#$%^&*()_\-+={}[\]\\|;:'"?/>.<,]{8,}$/.test(v) || 'Password must contain at least one letter, number and special character'],
        password_confirm: [(v) => !!v || 'Confirm password is required', () => this.user.password === this.user.password_confirm || "Passwords must match"],
        role: [v => !!v || 'Role is required'],
      }
    }
  },
  props: {
    show: Boolean,
    roles: Array,
    access: Object,
    createUser: Function,
  },
  watch: {
    show(newVal) {
      if (newVal === true) {
        this.$nextTick(() => {
          this.$refs.user_details.resetValidation();
          this.generatePassword();
        });
      }
    },
    user(newVal) {
      if (newVal) {
        this.new_user = clone(newVal);
      }
    }
  },
  methods: {
    validate(step) {
      switch (step) {
        case 1:
          this.step_1_valid = false;
          if (this.$refs.user_details.validate()) {
            this.step_1_valid = true;
            this.current_step = 2;
          }
          break;
        case 2:
          this.step_2_valid = false;
          if (this.$refs.user_access.validate()) {
            this.step_2_valid = true;
            this.current_step = 2;
          }
          break;
      }
    },
    async create(action) {
      // If both steps are valid, continue with creation
      if (this.$refs.user_details.validate() && this.$refs.user_access.validate()) {
        // Create new user and return boolean true/false depending on success
        let result = await this.createUser(action, this.user);
        
        // If user is created, reset the form
        if (result) {
          this.user = {
            user_access: {}
          }
          this.generatePassword();
        }
        this.current_step = 1;
        this.$refs.user_details.resetValidation();
        this.$refs.user_access.resetValidation();
        if (action === 'close') {
          this.$emit('update:show', false)
        }
      }
    },
    cancel() {
      this.$emit('update:show', false)
      this.user = {
        user_access: {}
      }
      this.current_step = 1;
      this.step_1_valid = true;
      this.step_2_valid = true;
      this.$refs.user_details.resetValidation();
      this.$refs.user_access.resetValidation();
    },
    generatePassword() {
      let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let numset = "0123456789";
      let specialcharset = "!@#$%^&*";
      let password = "";
      // Add letters
      for (var i = 0; i < 8; i++) {
        password += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      // Add numbers
      for (i = 0; i < 1; i++) {
        password += numset.charAt(Math.floor(Math.random() * numset.length));
      }
      // Add special characters
      for (i = 0; i < 1; i++) {
        password += specialcharset.charAt(Math.floor(Math.random() * specialcharset.length));
      }
      this.$set(this.user, 'password', password);
      this.$set(this.user, 'password_confirm', password);
    },
    copyPassword() {
      navigator.clipboard.writeText(this.user.password);
    }
  }
}
</script>