<template>
  <v-window style="overflow: visible;">
    <v-btn-toggle v-model="current_tab" mandatory style="justify-content: space-evenly;" class="background py-4" active-class="current-tab">
      <v-row>
        <v-col v-for="(tab, i) in tabs" :key="i">
          <v-btn elevation=4 class="button-style rounded-lg" :value="tab">{{ tab }}</v-btn>
        </v-col>
      </v-row>
    </v-btn-toggle>
    <v-divider class="mt-1 pb-5" />

    <div v-if="current_tab === 'Product Ratings'">
      <!-- Skeleton Loaders -->
      <v-row v-if="loading" dense>
        <v-col>
          <v-skeleton-loader
            type="card-heading, divider, list-item-three-line, card-heading, list-item-two-line"
            elevation="2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <!-- Main Page Content -->
      <v-row v-else>
        <v-col>
          <SettingsTabsWeightings/>
        </v-col>
      </v-row>
    </div>

    <div v-if="current_tab === 'Price Levels'">
      <!-- Skeleton Loaders -->
      <v-row v-if="loading" dense>
        <v-col>
          <v-skeleton-loader
            type="card-heading, divider, list-item-three-line, card-heading, list-item-two-line"
            elevation="2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <!-- Main Page Content -->
      <v-row v-else>
        <v-col>
          <SettingsTabsPriceLevels/>
        </v-col>
      </v-row>
    </div>

    <div v-if="current_tab === 'Users'">
      <!-- Skeleton Loaders -->
      <v-row v-if="loading" dense> </v-row>
      <!-- Main Page Content -->
      <v-row v-else>
        <v-col cols="12">
          <UsersTable
            :users="users"
            :roles="roles"
            :organization="organization"
            :access="access"
            :loading="loading"
            :requestUsers="requestUsers"
            :showUserDialog="showUserDialog"
            :createUser="createUser"
            :deleteUser="deleteUser"
            :goToUser="goToUser"
          ></UsersTable>
        </v-col>
      </v-row>
    </div>

    <div v-if="current_tab === 'Images'">
      <!-- Skeleton Loaders -->
      <v-row v-if="loading" dense> </v-row>
      <!-- Main Page Content -->
      <v-row v-else>
        <v-col cols="12">
          <SettingsTabsImages/>
        </v-col>
      </v-row>
    </div>

    <div v-if="current_tab === 'Development' && NODE_ENV === 'development'">
      <!-- Skeleton Loader -->
      <v-row v-if="loading">
        <v-col cols="12">
          <v-skeleton-loader
            type="card-heading, divider, list-item-three-line, card-heading, list-item-two-line"
            elevation="2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <!-- Main Page Content -->
      <v-row v-else>
        <v-col cols="12">
          <!-- Reset Database Button -->
          <v-btn text elevation="2" block color="error" @click="resetDatabase()">Reset Database</v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="mt-2 rounded-lg" block @click="regenProductRatings">Regenerate Product Ratings</v-btn>
            </template>
            <span>Will generate/recalculate product ratings for all products in ItemFlo.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>

    <div v-if="current_tab === 'Staging' && NODE_ENV === 'staging'">
      <!-- Main Page Content -->
      <v-row>
        <v-col cols="12">
          <!-- Submit Bug Form -->
          <iframe
            src="https://forms.monday.com/forms/embed/67ebcfe07fd5bd75ce84aab907d013f1?r=use1"
            width="100%"
            height="900px"
            style="border: 0; box-shadow: 5px 5px 56px 0px rgba(0, 0, 0, 0.25)"
          ></iframe>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="rounded-lg" block @click="regenProductRatings">Regenerate Product Ratings</v-btn>
            </template>
            <span>Will generate/recalculate product ratings for all products in ItemFlo.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
  </v-window>
</template>

<script>
import UsersTable from "../../components/settings/UsersTable.vue";
import SettingsTabsWeightings from "@/components/settings/SettingsTabsWeightings.vue";
import SettingsTabsImages from "@/components/settings/SettingsTabsImages.vue";
import SettingsTabsPriceLevels from './SettingsTabsPriceLevels.vue';
  export default {
    data() {
      return {
        current_tab: null,
      };
    },
    props: {
      users: Array,
      roles: Array,
      organization: Object,
      loading: Boolean,
      access: Object,
      showUserDialog: Boolean,
      NODE_ENV: String,
      requestUsers: Function,
      createUser: Function,
      deleteUser: Function,
      goToUser: Function,
      resetDatabase: Function,
    },
    computed: {
      tabs() {
        let tabs = ["Users", "Product Ratings", "Price Levels"];
        if (this.organization.module_image) {
          tabs.push('Images');
        }
        if (this.NODE_ENV === "development") {
          tabs.push('Development');
        } else if (this.NODE_ENV === "staging") {
          tabs.push('Staging');
        }
        return tabs;
      },
    },
    components: {
      UsersTable,
      SettingsTabsWeightings,
      SettingsTabsImages,
      SettingsTabsPriceLevels,
    },
    methods: {
      async regenProductRatings() {
        // Update the product ratings
        await this.$axios.put(`${process.env.VUE_APP_API_BASE_URL}/metrics/products/ratings`)
          .catch(err => {
            console.log(err);
          });
        this.$store.dispatch('showSnackbar', {text: "Product ratings recalculated successfully.", color: "success", timeout: 2500});
      }
    }
  };
</script>
